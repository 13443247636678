.buttonFilterWrap {
    position: relative;
    z-index: 9;

    .filterBoxWrap {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        max-width: 320px;
        width: 100vw;
        background: var(--white);
        box-shadow: 0px 0px 10px 0px #0000001A;
        border-radius: 6px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease-in;

        &.active{
            visibility: visible;
            opacity: 1;
            transition: 0.2s ease-in;
        }

        h4 {
            font-family: var(--montSemiBold);
            font-size: 13px;
            font-weight: 700;
            line-height: 15.6px;
            padding: 16px 20px;
            border-bottom: 1px solid var(--black7);
        }

        .filterInside{
            position: relative;
            padding: 20px 20px;
        }

        .selectorBox{
            position: relative;

            .labelz{
                font-family: var(--montSemiBold);
                font-size: 13px;
                font-weight: 700;
                line-height: 15.6px;
                margin-bottom: 10px;
                display: block;
            }

            .selectorWrap{
                margin-top: 0 !important;
            }
        }

        .propertyType{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
            row-gap: 10px;

            .filterSelector{
                max-width: calc(50% - 10px);
                width: 100%;
                padding: 0;
            }
        }

        .filterButtonGroup{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            padding: 10px 0;
            margin-top: 20px;
        }
    }
}