.dashWrapper {
    position: relative;

    .percent100 {
        max-width: 100%;
        width: 100%;
    }

    .percent80 {
        max-width: calc(80% - 10px);
        width: 100%;
    }

    .percent70 {
        max-width: calc(70% - 10px);
        width: 100%;
    }

    .percent60 {
        max-width: calc(60% - 10px);
        width: 100%;
    }

    .percent50 {
        max-width: calc(50% - 10px);
        width: 100%;
    }

    .percent40 {
        max-width: calc(40% - 10px);
        width: 100%;
    }

    .percent30 {
        max-width: calc(30% - 10px);
        width: 100%;
    }

    .percent20 {
        max-width: calc(20% - 10px);
        width: 100%;
    }

    .dashMain {
        margin-top: 20px;
        position: relative;
    }

    .totalWrap {
        padding: 30px 20px;
        border-radius: 6px;
        background-color: var(--blue);
        color: var(--white);

        display: flex;
        align-items: center;
        justify-content: space-between;

        .totalDetail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 24px;

            .detail {
                display: block;
                max-width: calc(100% - 100px);

                h5 {
                    font-family: var(--springSemiBold);
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 26px;
                    width: 100%;
                }

                p {
                    font-family: var(--montRegular);
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    margin-top: 5px;
                }
            }
        }

        .totalCount {
            font-family: var(--montBold);
            font-size: 42px;
            font-weight: 800;
            line-height: 50px;
        }
    }

    .chartWrapper {
        margin-top: 20px;
        position: relative;

        .chartSM {
            background: var(--white);
            padding: 25px 18px;
            border-radius: 10px;
            overflow: hidden;
            max-width: calc(50% - 5px);
            width: 100%;
        }

        .percent50 {
            &:nth-child(2) {
                text-align: right;

                svg {
                    width: auto;
                    min-height: 100px;
                }
            }
        }

        .smCount {
            font-family: var(--montBold);
            font-size: 22px;
            font-weight: 800;
            line-height: 26px;
            color: var(--black);
        }

        .smText {
            font-family: var(--montRegular);
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-top: 4px;
            color: var(--black1);
        }
    }

    .listWrapper {
        position: relative;
        margin-top: 20px;

        .listrLeft {
            padding: 22px 20px;
            background: var(--white);
            border-radius: 6px;
            overflow: hidden;
            max-width: calc(50% - 5px);
            width: 100%;

            h4 {
                font-family: var(--montBold);
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                margin-bottom: 20px;
            }

            .list {
                max-width: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                font-size: 12px;
                font-weight: 400;
                line-height: 21px;
                padding-bottom: 14px;
                margin-bottom: 14px;
                border-bottom: 1px solid var(--black9);
                color: var(--black1);

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

                .listRight {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    b {
                        color: var(--black);
                    }
                }
            }
        }

        .listrRight {
            padding: 22px 20px;
            background: var(--white);
            border-radius: 6px;
            overflow: hidden;
            max-width: calc(50% - 5px);
            width: 100%;

            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;

            h4 {
                font-family: var(--montBold);
                font-size: 22px;
                font-weight: 800;
                line-height: 32px;
            }

            p {
                font-family: var(--montRegular);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                margin-top: 2px;
                color: var(--black1);
            }

            .innerBottom {

                span {
                    font-family: var(--montRegular);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    color: var(--grey9);
                }

                .heroes {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0;

                    span {
                        font-size: 12px;
                        line-height: 100%;
                        padding: 2px;
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--yellow);
                        color: var(--white);
                        border-radius: 50%;
                        border: 3px solid var(--yellowLight);
                        position: relative;
                        margin-left: -6px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

            }
        }

    }

    .whatsToday {
        max-width: 100%;
        width: 100%;
        background: var(--white);
        border-radius: 6px;

        .whatsTop {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 20px 20px;
            border-bottom: 1px solid var(--black7);

            .whatsText {
                min-width: max-content;

                h4 {
                    font-family: var(--montBold);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: var(--black5);
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    color: var(--grey5);
                    margin-top: 4px;
                }
            }
        }

        .whatsBottom {
            padding: 22px 20px;

            .dateTimeline {
                margin-top: 28px;

                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 18px;
                flex-wrap: wrap;
                max-height: calc(50vh - 28px);
                overflow: auto;
            }
        }

    }

    .projectStatus {
        position: relative;
        margin-top: 20px;
        background: var(--white);

        &.recentTabs {
            .tableBox {
                max-width: 15%;

                @media (max-width: 1600px) {
                    max-width: 15%;
                }

                &:first-child {
                    max-width: 25%;
                }

                &:last-child{
                    max-width: 10%;
                }
            }
        }


        .rightFloating {
            max-height: 220px;
            overflow: auto;

            .tableRow{
                flex-wrap: wrap;
            }
        }

        .whatsTop {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 20px 20px;
            border-bottom: 1px solid var(--black7);

            .whatsText {
                min-width: max-content;

                h4 {
                    font-family: var(--montBold);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: var(--black5);
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15px;
                    color: var(--grey5);
                    margin-top: 4px;
                }
            }
        }
    }

    .topRated {
        margin-top: 20px;
        position: relative;

        .projectStatus {
            margin-top: 0;
        }

        .ratingBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
        }

        .staticsWrapper {
            padding: 20px 20px;

            .staticsWrap {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 20px;
                row-gap: 4px;
                flex-wrap: wrap;
                width: 100%;
                margin-top: 15px;
                padding-bottom: 35px;
                border-bottom: 1px dashed var(--grey3);

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: 0;
                }

                .statLeft {
                    position: relative;

                    h4 {
                        font-family: "Inter", sans-serif;
                        font-size: 23px;
                        font-weight: 600;
                        line-height: 33px;
                    }

                    span {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 19px;
                        color: var(--grey5);
                    }
                }

                .statRight {
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                }

                .processBox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    max-width: 100%;
                    overflow: hidden;

                    height: 6px;
                    border-radius: 6px;
                    background: var(--grey8);

                    &.greenBar {
                        .process {
                            background: var(--green);
                        }
                    }

                    &.yellowBar {
                        .process {
                            background: var(--yellow);
                        }
                    }

                    .process {
                        background: var(--blue);
                        position: relative;
                        max-width: 40%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.divTableWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 20px;

    .tableRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px dashed var(--grey3);
        padding-bottom: 10px;
        margin-bottom: 10px;
        gap: 10px;

        &.recentTabs{
            flex-wrap: wrap;
        }

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        .tableBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            column-gap: 10px;
            max-width: 25%;
            width: 100%;

            &.tableUser {
                flex-wrap: nowrap;
            }

            img.propImg {
                max-width: 40px;
                min-width: 40px;
                max-height: 40px;
                height: 40px;
                border-radius: 5px;
                overflow: hidden;
                object-fit: cover;
                object-position: center;
            }

            &.redirectIcon {
                justify-content: flex-end;
                padding-right: 50px;
            }

            p {
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                width: 100%;
                display: block;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
                color: var(--grey5);
                width: 100%;
                display: block;
            }
        }
    }
}

.colGap100 {
    column-gap: 100px !important;
}