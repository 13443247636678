.flexbox{
    display: flex;
    position: relative;
}

.wrap{
    flex-wrap: wrap;
}

.noWrap{
    flex-wrap: nowrap;
}

.alignCenter{
    align-items: center;
}

.justiCenter{
    justify-content: center;
}

.column{
    flex-direction: column;
}

.row{
    flex-direction: row;
}

.colRev{
    flex-direction: column-reverse;
}

.rowRev{
    flex-direction: row-reverse;
}

.spaceBW{
    justify-content: space-between;
}

.flexStart{
    justify-content: flex-start;
}

.flexEnd{
    justify-content: flex-end;
}

.alignStart{
    align-items: flex-start;
}

.alignEnd{
    align-items: flex-end;
}

.stretch{
    align-items: stretch;
}