.paymentWrap {
    position: relative;

    .propertyTable {
        // margin-top: 20px;
        border-top: 1px solid rgba(0,0,0,0.04);
    }


    .propertyTable {
        padding: 20px;
        background: var(--white);
    }

    //booking
    .bookingWrapper {
        padding: 20px;
    }


    table {
        border-collapse: collapse;
        width: 100%;
        position: relative;
    }

    thead {
        background-color: var(--black10);

        th {
            padding: 16px 20px;
            text-align: left;
            font: 14px var(--montBold);
            line-height: 17px;
            color: var(--black1);
            min-width: max-content;
        }
    }

    tbody {

        tr {
            border-bottom: 1px dashed var(--grey4);
        }

        td>div {
            // padding: 12px 20px;
            text-align: left;
            font: 13px var(--montRegular);
            line-height: 16px;
            color: var(--black);
            min-width: max-content;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }

    .countBar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 15px;

        p{
            font-family: var(--montRegular);
            font-size: 12px;
            font-weight: 500;
            line-height: 120%;
            color: var(--black1);

            b{
                font-weight: 600;
            }
        }

        .buttons{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }

    .wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: var(--white);
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid var(--grey4);

        input{
            background: transparent;
            border: none;
            height: auto;
            outline: none;
        }
    }
}

.textGreen{
    color: var(--green);
}
