.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .video-popup.visible {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-popup-content {
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    position: relative;

    span{
      text-align: right;
      display: block;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #000;
      min-width: 31px;
      border-radius: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg{
        display: inline-block;

        rect{
          fill: #fff;
        }
      }
    }
  }
  
  .video-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    cursor: pointer;
  }
  
  video {
    width: 100vw;
    max-width: 900px;
    line-height: 0;
  }
  