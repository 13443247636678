.cardWrapper{
    max-width: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;

    padding: 16px 16px;
    background: var(--white);
    border-radius: 10px;
    cursor: pointer;

    .cardLeft{
        img{
            max-width: 97px;
            border-radius: 12px;
        }
    }

    .cardRight{
        h4{
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 6px;
        }

        .detail{

            span{
                font-size: 11px;
                font-weight: 400;
                line-height: 120%;
            }

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 4px;

            svg{
                min-width: 16px;
                min-height: 16px;
            }

            &:not(:first-child){
                margin-top: 4px;
            }
        }
    }
}