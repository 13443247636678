.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Increase the z-index to ensure it appears above other content */
  }
  .popup-content.newpop-up-content{
    justify-content: space-around;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow for depth */
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    width: 100%;

    button {
      margin: 0 5px;
      padding: 8px 16px;
      border: none;
      background-color: #007bff;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 100%;
    }
  }
  
  .buttons button:hover {
    background-color: #0056b3;
  }
  