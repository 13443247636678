.popupWrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3500;
    background: var(--black6);
    backdrop-filter: blur(2px);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease-in;

    &.active{
        visibility: visible;
        opacity: 1;
        transition: 0.4s ease-in;
    }

    .popup{
        background: var(--white);
        max-width: 700px;
        width: 100%;
        border-radius: 10px;
    }

    .popupHeader{
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--black6);

        h3{
            font-family: var(--springBold);
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
        }

        span{
            cursor: pointer;
        }
    }

    .popupBody{
        padding: 15px 30px;
        max-height: 500px;
        overflow: auto;
    }

    .popupFooter{
        border-top: 1px solid var(--black6);
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }

}