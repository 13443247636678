.optionsWrap{
    display: block;
    position: relative;
    z-index: 999;
    cursor: pointer;

    &.active{
        ul{
            visibility: visible;
            opacity: 1;
            transition: 0.4s ease-in;
        }
    }

    .Icon{
        cursor: pointer;
        z-index: 1;
    }

    ul{
        position: absolute;
        min-width: max-content;
        right: 100%;
        top: -80%;
        background: var(--white);
        border-radius: 6px;
        box-shadow: 0px 0px 10px 0px #0000001A;
        padding: 10px 10px;
        visibility: hidden;
        opacity: 0;
        transition: 0.4s ease-in;
        z-index: 1000;

        li{
            color: var(--black);
            font-family: var(--montRegular);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border-radius: 4px;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            padding: 6px 8px;
            transition: 0.4s ease-in;

            svg{
                min-width: 14px;
                max-width: 14px;
                min-height: 14px;
                max-height: 14px;

                path{
                    transition: 0.4s ease-in;
                }
            }

            &:hover{
                svg path{
                    fill: var(--primary10);
                    transition: 0.4s ease-in;
                }
            }

            &:not(:first-child){
                margin-top: 2px;
            }

            &:hover,
            &.active,
            &:active{
                background: rgba(173, 72, 216, 0.06);
                color: var(--primary10);
                transition: 0.4s ease-in;
            }
        }
    }
}