.formGroup{
    display: block;
    position: relative;
    width: 100%;

    &.fontPoppins{
        font-family: 'Poppins', sans-serif;
        
        label{  
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
        }
    }

    &.checbox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        flex-direction: row-reverse;

        label{
            margin: 0;
            color: var(--black1);
            cursor: pointer;
            font-size: 12px;
        }

        input{
            max-width: max-content;
            cursor: pointer;

            &:focus{
                outline: none;
            }
    
            &:focus-visible{
                outline: none;
            }
    
            &:focus-within{
                outline: none;
            }
        }
    }

    label{
        position: relative;
        width: 100%;
        margin-bottom: 4px;
        color: var(--black);
        display: block;
        font-size: 14px;
        line-height: 150%;
        font-family: var(--springRegular);
    }

    input{
        padding: 16px 16px;
        position: relative;
        display: block;
        max-width: 100%;
        width: 100%;
        color: var(--black5);

        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: var(--white);
        transition: 0.4s;

        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;

        ::placeholder{
            color: var(--black1);
        }

        &:focus{
            outline: 1px solid var(--grey5);
        }

        &:focus-visible{
            outline: 1px solid var(--grey5);
        }

        &:focus-within{
            outline: 1px solid var(--grey5);
        }
    }
}