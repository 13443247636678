.checboxWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px;  
  
    position: relative;
    padding-left: 0;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    gap: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--grey7);
  }

  .checboxWrap.disable{
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .checboxWrap:not(:last-child){
    border-bottom: none;
  }

  .checboxWrap:not(:last-child):before{
    content: '';
    position: absolute;
    left: 10%;
    bottom: 0px !important;
    width: 90%;
    height: 2px;
  }

  /* Hide the browser's default checkbox */
  .checboxWrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmarkNew {
    position: relative;
    height: 14px;
    min-width: 14px;
    background-color: var(--white);
    border: 1px solid var(--grey7);
    border-radius: 5px;
  }
  
  /* On mouse-over, add a grey background color */
  .checboxWrap:hover input ~ .checkmarkNew {
    background-color: #ccc;
  }

  .checboxWrap.disable:hover input ~ .checkmarkNew {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checboxWrap input:checked ~ .checkmarkNew {
    background-color: var(--blue);
    border-color: var(--blue);
  }
  
  /* Create the checkmarkNew/indicator (hidden when not checked) */
  .checkmarkNew:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmarkNew when checked */
  .checboxWrap input:checked ~ .checkmarkNew:after {
    display: block;
  }
  
  /* Style the checkmarkNew/indicator */
  .checboxWrap .checkmarkNew:after {
    left: 5px;
    top: 2px;
    width: 2px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

