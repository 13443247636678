.cardWrap{
    position: relative;
    background: var(--white);
    text-align: center;
    max-width: 215px !important;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--black6);
    width: 100%;

    > img{
        max-width: 80px;
        max-height: 80px;
        object-fit: cover;
        width: 100%;
        overflow: hidden;
        object-position: top;
        border-radius: 10px;
    }

    .agentcode{
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: rgba(115, 115, 115, 1);
        margin-top: 12px;
    }

    .agentName{
        font-family: var(--montBold);
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
    }

    .location{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: rgba(115, 115, 115, 1);
        font: 11px 'montRegular';
        // flex-direction: column;
        margin-top: 16px;
        text-align: left;

        svg{
            min-width: 20px;
        }
    }

    .properties{
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 5px;
    }

    .contact{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        margin-top: 16px;
    }

    .contact button:first-child{
        max-width: 75%;
        width: 100%;
    }

    .contact button:nth-child(2){
        max-width: 25%;
        width: 100%;
    }

    .floatingOptionsNew{
        display: block;
        z-index: 999;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;

        ul{
            left: 0px;
            top: 100%;
        }
    }

}