.filterBox {
    position: relative;
    
    .wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: var(--white);
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid var(--grey4);

        input {
            background: transparent;
            border: none;
            height: auto;
            outline: none;
        }
    }
}

.filterBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}