.formWrapper {

    background: white;
    border-radius: 10px;
    margin-top: 20px;

    h2 {
        font: 16px 'montHeavy';
        color: #171717;
        padding: 20px;
        border-bottom: 1px solid #F5F5F5;
    }

    .outerBox {
        padding: 20px;
        background: white;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .inputFormWrapper {
            padding: 20px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: flex-start;
            background: var(--grey8);
            border-radius: 10px;



            .heading {
                padding: 16px 0;
                color: #737373;
                font: 13px 'montbold';
                border-bottom: 1px solid var(--grey4);
            }

            .inputFieldWrapper2 {
                display: flex;
                gap: 20px;
                align-items: center;
            }
        }
    }
}

.ButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;

    .buttonRight {
        display: flex;
        width: 20%;
        gap: 6px;
    }
}

.features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.additionalWrap {
    flex-wrap: wrap;

    .additionalFeatures {
        display: block;
        position: relative;
        width: 100%;
    }
}

.labelz {
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 120%;
    color: var(--black);
    margin-bottom: 10px;
    width: 100%;
    font-weight: 400;
}

.uploadBox {
    flex-wrap: wrap;
    gap: 0 !important;

    label:nth-child(2) {
        width: 100%;
        min-height: 200px;
        max-height: 200px;
        padding: 16px 20px;
        border: 1px solid #EEEEEE;
        max-width: 100%;
        width: 100%;

        background: url('../../../../public/assets/svg/uploadCloud.svg') no-repeat;
        background-position: 50% 50%;

        svg {
            display: none;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            margin-top: 50px;
        }

    }
}

.formTimeline {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .formTimelineBox {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-direction: column;

        p {
            font: 13px 'montSemibold';
            color: #737373;

        }

        svg {
            filter: grayscale(1);
        }
    }

    .formTimeline.active {

        .formTimelineBox {

            p {
                font: 13px 'montBold';
                color: #000;
            }

            svg {
                filter: grayscale(0);
            }
        }
    }
}

.formGroup {
    position: relative;
    display: block;
    width: 100%;

    label {
        display: block;
        position: relative;
        font-size: 12px;
        line-height: 120%;
        color: var(--black);
        margin-bottom: 10px;
        font-weight: 400;
    }

    select {
        background: var(--white);
        height: 49px;
        border-radius: 6px;
        display: block;
        width: 100%;
        padding: 16px 20px;
        border: 1px solid #EEEEEE;
        color: var(--black1);
        font-weight: 400;
        position: relative;

        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

    }

    &::after {
        content: '';
        background: url('../../../../public/assets/svg/arrow-down.svg');
        width: 100%;
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 100% 70%;
        right: 10px;
    }
}