table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px 16px;
    text-align: left;
    line-height: 17px;
    min-width: max-content;

}

td {
    color: black;
    font: 13px 'montRegular';
}

thead {
    background: var(--black10);
    border-radius: 10px, 10px, 0px, 0px;
    border-bottom: 1px solid var(--black9);
}

th {
    padding: 16px 20px;
    text-align: left;
    font: 14px var(--montBold);
    line-height: 17px;
    color: var(--black1);
    min-width: max-content;
}

.propertyFflex {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
        max-width: 32px;
        min-height: 32px;
        border-radius: 4px;
    }
}

.propertyFflex2 {

    div {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    img {
        max-width: 32px;
        min-height: 32px;
        border-radius: 4px;
    }
}

.tabData {
    display: flex;
    gap: 4px;
}

.tabDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    h3 {
        font: 13px 'montRegular';
        color: black;
    }

    p {
        font: 11px 'montRegular';
        color: var(--grey10);
    }
}

tr:not(:first-child) {
    border-bottom: 1px dashed var(--grey4);
}



.countBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    position: relative;

    p {
        font-family: var(--montRegular);
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
        color: var(--black);

        b {
            font-weight: 600;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--grey4);

    input {
        background: transparent;
        border: none;
        height: auto;
        outline: none;
    }
}