.chatPerson {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    max-width: 350px;
    width: 100%;
    padding: 20px 20px;
    background: var(--white2);
    transition: 0.4s ease-in;
    cursor: pointer;

    &:hover,
    &.active{
        background: var(--black7);
        transition: 0.4s ease-in;
    }

    &:not(first-child){
        border-bottom: 1px dashed var(--grey3);
    }

    img {
        max-width: 32px;
        height: auto;
        object-fit: contain;
    }

    .chatRight {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: calc(100% - 32px);
        width: 100%;

        .asideLeft {
            position: relative;

            .userName{
                font-size: 13px;
                font-weight: 800;
                line-height: 16px;
                font-family: var(--montMedium);
            }

            .lastMsg{
                font-size: 11px;
                font-weight: 400;
                line-height: 13px;
                margin-top: 6px;
            }
        }

        .asideRight{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            text-align: right;
            row-gap: 6px;

            .time{
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                width: 100%;
            }

            .notification{
                position: relative;

                font-size: 10px;
                font-weight: 400;
                line-height: 120%;
                padding: 2px;
                width: 16px;
                height: 16px;
                overflow: hidden;
                border-radius: 50%;
                background: var(--blue);
                color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }
}

.chatWrap {
    position: relative;
    
    .chatWrapper{
        margin-top: 20px;
        position: relative;
        max-height: calc(100vh - 185px);
        overflow: hidden;

        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        .chatLeft{
            max-width: 350px;
            width: 100%;

            height: 100vh;
            max-height: inherit;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 15px;
            }
            
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .searchWrapper{
                padding: 16px 20px;
                background: var(--white);
                position: relative;
                position: sticky;
                top: 0;
                background: var(--white);
                z-index: 99;

                span{
                    position: absolute;
                    right: 30px;
                    top: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                input{
                    padding: 12px 16px;
                    background: var(--white);
                    border: 1px solid var(--black6);
                    outline: none;
                    width: 100%;

                    &:focus,
                    &:focus-visible,
                    &F:focus-within{
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .chatScreen{
            position: relative;
            max-width: calc(100% - 350px);
            width: 100%;
            border-left: 1px solid var(--black6);

            height: 100vh;
            max-height: inherit;
            overflow: auto;
            background: var(--white2);

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 15px;
            }
            
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .user{
                position: sticky;
                top: 0;
                width: 100%;
                position: relative;
                padding: 20px 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                border-bottom: 1px solid var(--black6);

                img{
                    max-width: 32px;
                    object-fit: cover;
                    height: auto;
                }

                .userName{
                    font-family: var(--springRegular);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                }
            }

            .chatArea{
                padding: 20px 20px;
                background: var(--white);
                position: relative;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                height: 100%;
                max-height: calc(100% - 185px);
                overflow: auto;
            }

            .msgSender{
                padding: 20px 20px;
                background: var(--white);
                width: 100%;
                position: relative;

                span{
                    position: absolute;
                    right: 40px;
                    top: 36px;
                    line-height: 0;
                }

                input{
                    background: var(--grey1);
                    padding: 26px 20px;
                    width: 100%;
                    border: 1px solid var(--grey1);

                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    color: var(--black1);

                    ::placeholder{
                        color: var(--black1);
                    }

                    &:focus,
                    &:focus-visible,
                    &F:focus-within{
                        outline: none;
                        box-shadow: none;
                    }

                }
            }
        }
    }
}

// chat bubble

.chatBubble{
    max-width: 60%;
    width: max-content;
    padding: 16px 16px;    
    background: var(--white4);
    border-radius: 10px 10px 20px 0px;
    text-align: left;
    display: block;

    &:not(:first-child){
        margin-top: 5px;
    }
    
    &.senderBubble{ 
        margin-left: auto;
        border-radius: 10px 10px 0px 20px;
        background: var(--lightBlue);
        text-align: right;
    }

    &.senderBubble+.timeLast{
        text-align: right;
    }
}

.timeLast{
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    color: var(--black2);
    margin-top: 10px;
    display: block;
}