.ErrorPageforallscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.ErrorPageforallscreen .text2 {
    text-align: center;
    font-size: 14px;
}