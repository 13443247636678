button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    max-height: 52px;
    height: 52px;
    width: 100%;
    border: 1px solid transparent;
    background: var(--white);
    width: 100%;
    cursor: pointer;
    padding: 16px 20px;
    border-radius: 6px;

    font-size: 14px;
    font-family: var(--montRegular);
    font-weight: 800;
    line-height: 150%;

    &:disabled{
        cursor: not-allowed;
        opacity: 0.6;
        background: var(--grey5);
        border-color: var(--grey5);
    }

    &.reverseIcon{
        flex-direction: row-reverse;
    }

    &.blueBtn{
        background: var(--darkBlue);
        color: var(--white);
        font-family: var(--montBold);
    }

    &.blueBorderBtn{
        background: inherit;
        color: var(--blue);
        border-color: var(--blue);
    }

    &.blueBtnLight{
        background: var(--blue);
        color: var(--white);
    }

    &.whiteBtn{
        border-color: rgba(0, 0, 0, 0.10);
        font-weight: 400;
        font-family: var(--montRegular);
        color: var(--black);
    }

    &.greyBtn{
        background: var(--grey8);
        font-weight: 500;
        color: var(--grey10);
    }

}
