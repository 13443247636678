.agentWrap{
    position: relative;
}

.agentFilter{
    margin-top: 20px;
    cursor: pointer;
}

.cardArrangement{
    position: relative;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    .custCard{
        max-width: calc(33% - 10px);
        width: 100%;

        @media (min-width: 1600px) {
            max-width: calc(25% - 20px);
        }
    }
}