.cardWrapIn{
    position: relative;
    width: 100%;
    cursor: pointer;

    img{
        width: 100%;
    }

    h5{
        margin-top: 20px;
        font-family: var(--montRegular);
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: var(--black);
    }

    p{
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: var(--black1);
    }

    span{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 10px;
        font-family: var(--montRegular);
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
    }
}