.headerWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Left{
        position: relative;

        h4{
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            font-family: var(--montBold);
            margin-bottom: 2px;
        }

        p{
            font-family: var(--montRegular);
            font-size: 12px;
            font-weight: 500;
            line-height: 120%;
            color: var(--black1);

            strong{
                font-family: var(--montSemiBold);
                font-weight: 800;
            }
        }
    }

    .Right{
        position: relative;

        &.addFlex{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            cursor: pointer;
        }

        button{
            padding: 12px 16px;
            height: 40px;
        }
    }
}