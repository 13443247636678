.agentWrap{
    position: relative;
}

.agentFilter{
    margin-top: 20px;
    cursor: pointer;
}

.cardArrangement{
    position: relative;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;

    .custCard{
        max-width: calc(25% - 20px);
        width: 100%;
    }
}

.agentWrapper{
    position: relative;
    background: var(--white);
    padding: 30px 30px;
    border-radius: 10px;

    .agentBoxWrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--grey11);
    }

    .agentBox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        img{
            max-height: 100px;
            border-radius: 10px;
            object-fit: cover;
        }

        h4{
            font-family: var(--montSemiBold);
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            margin-bottom: 6px;
        }

        span{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            position: relative;
            width: 100%;

            font-size: 11px;
            font-weight: 400;
            line-height: 15.4px;
        }
    }

    .projectTabs{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--grey11);

        li{
            font-family: var(--springRegular);
            font-size: 16px;
            font-weight: 700;
            line-height: 19.2px;
            text-align: left;
            cursor: pointer;

            &.active{
                color: var(--blue);
            }
        }
    }
}