.tagWrap{
    border-radius: 4px;
    padding: 6px 8px;
    gap: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    max-width: max-content;
    text-transform: capitalize;

    font-size: 11px;
    font-weight: 500;
    line-height: 16px;

    color: var(--blue);
    background: rgba(14, 119, 211, 0.12);

    svg{
        height: 14px;
        width: auto;
    }

    span{
        line-height: normal;
        display: block;
        color: inherit !important;
    }
    
    &.green{
        background: rgba(74, 181, 142, 0.12);
        color: var(--green);
    }

    &.yellow{
        background: rgba(255, 168, 0, 0.12);
        color: var(--yellow);
    }

    &.purple{
        background: rgba(90, 26, 183, 0.12);
        color: var(--purple);
    }

    &.red{
        background: rgba(229, 62, 62, 0.1);
        color: var(--red);
    }
}