.filterWrap {
    position: relative;
    padding: 20px 20px;
    background: var(--white);

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid var(--grey2);

    svg{
        transform: rotate(180deg);
        transition: 0.4s ease-in;
    }

    &.active{
        svg{
            transform: rotate(0deg);
            transition: 0.4s ease-in;
        }
    }

    h4 {
        font-family: var(--springSemiBold);
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
    }
}

.filterBox {
    padding: 0;
    transition: 0.4s;
    background: var(--white);
    height: 0;
    opacity: 0;
    
    &.active{
        padding: 16px 20px;
        height: auto;
        transition: 0.4s;
        opacity: 1;
    }

    .searchWrap {
        padding: 30px 30px;
        border: 1px solid var(--grey2);
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        input {
            border: none;
            width: 100%;
            color: var(--black1);
            font-size: 12px;
            line-height: 120%;

            &:focus,
            &:focus-visible,
            &:focus-within {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

    .optionWrap {
        margin-top: 10px;

        &.flexer{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }

    }
}

.selectorWrapper {
    position: relative;
    display: block;
    max-width: max-content;

    span{
        &:first-child{
            position: absolute;
            right: 10px;
            top: 0;
            height: 100%;
            width: auto;

            display: flex;
            align-items: center;
            justify-content: center;

            svg{
                transform: rotate(180deg);
            }
        }
    }

    select {
        padding: 12px 20px;
        min-width: 260px;
        border: 1px solid var(--grey2);
        border-radius: 6px;
        -webkit-appearance: none;

        color: var(--black1);
        font-size: 12px;
        line-height: 120%;

        &:focus,
        &:focus-visible,
        &:focus-within {
            outline: none;
            box-shadow: none;
        }
    }
}

.filterFlexBox{
    display: flex;
    gap: 1%;
}