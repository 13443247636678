.sideMenuWrapper{
    display: block;
    position: fixed;
    width: 100%;
    max-width: 65px;
    background: var(--black);
    height: 100%;
    padding: 15px 15px;
    z-index: 99;
    transition: 0.4s ease-in;

    &.active{
        max-width: 245px;
        transition: 0.4s ease-in;

        .mainSideWrap{
            .sidmenuList{
                li{
                    padding: 10px 15px;
                    transition: 0.4s ease-in;
                    transition-delay: 0.2s;
                    // justify-content: flex-start;

                    min-width: max-content;
                    max-width: 100%;


                    img{

                    }

                    
                    span{
                        // display: block;
                        background: transparent;
                        width: 100%;
                        transition: 0.4s ease-in;
                    }
                }
            }

        }

    }

    .humButton{
        position: absolute;
        right: -20px;
        top: 12px;
        cursor: pointer;
        z-index: 99;
        
        img{
            transform: rotate(180deg);
            transition: 0.4s ease-in;
        }
        
        &.active{
            img{
                transform: rotate(0deg);
                transition: 0.4s ease-in;
            }
        }
    }

    .mainSideWrap{
        position: relative;

        .mainLogo{
            margin-bottom: 35px;
        }

        .logoWrap{
            width: 100%;
            overflow: hidden;
        }

        .menuHeading{
            display: block;
            color: var(--grey7);
            font-size: 12px;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
            margin-left: 15px;
            margin-bottom: 10px;
            min-width: max-content;
        }

        .sidmenuList{
            width: 100%;
            display: block;
            position: relative;

            li{
                color: var(--grey7);
                font-family: var(--montRegular);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                padding: 10px 0px;
                transition: 0.4s ease-in;
                transition-delay: 0.2s;
                cursor: pointer;
                border-radius: 5px;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                max-width: 34px;
                min-width: unset;
                overflow: hidden;
                padding-left: 10px;

                span{
                    margin-top: 0 !important;
                    min-width: max-content;
                    // display: none;
                }

                img{
                    min-width: 16px;
                }
            }
            
            .active{
                background: var(--blue);
                color: var(--white);
                transition: 0.4s ease-in;
            }
            
            .active img{
                filter: brightness(10);
                transition: 0.4s ease-in;
            }

            :hover{
                background: var(--blue);
                color: var(--white);
                transition: 0.4s ease-in;
            }
            
            :hover img{
                filter: brightness(10);
                transition: 0.4s ease-in;
                background: transparent;
            }

            :not(:first-child){
                margin-top: 5px;
            }
        }
    }

    .mainLogo{
        min-width: max-content;
    }
}