.projectOverview {
    position: relative;
    margin-top: 20px;
    border-radius: 10px;
}


.projectTabs {
    list-style-type: none;
    display: flex;
    gap: 40px;
    padding: 20px;
    border-radius: 12px 12px 0 0;
    background: var(--white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

    li {
        position: relative;
        font: 16px var(--springBold);
        color: var(--black1);
        cursor: pointer;
        transition: 0.4s ease-in-out;

        &:hover {
            color: var(--blue);
        }

        &.active{
            color: var(--blue);
        }
    }
}

.projectPages {
    background: var(--white);
}

//about

.aboutProjectWrapper {
    padding: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-start;

    h3 {
        font: 22px var(--montBold);
        color: var(--black);
    }

    .contentText {
        font: 12px 'montRegular';
        color: var(--black1);
        margin-top: 15px;
        line-height: 18px;
    }

    h5 {
        margin-top: 35px;
        font: 14px 'montBold';
        line-height: 21px;
    }

    .aboutProjectLeft {
        width: 75% ;


        .headRating {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .tag {
                padding: 4px 10px;
                background-color: #FFA8001A;
                border-radius: 4px;
                gap: 4px;
                display: flex;
                align-items: center;
                font: 14px var(--montSemibold);

            }
        }

        .locationDetails {
            display: flex;
            gap: 15px;
            margin-top: 5px;

            .box {
                font: 11px var(--montRegular);
                color: var(--black1);
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
        .projectDetailWrap{
            display: flex;
            gap: 10px;
            align-items: center;
            margin-top: 10px;

            .projectTag{
                border: 1px dashed var(--grey7);
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 5px;
                color: var(--black1);
                font: 13px var(--montRegular);
            }
        }
        .featuresWrap{
            display: flex;
            gap: 12px 35px;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;

            .contentText{
                margin-top: 0;
            }
        }
    }

    .aboutProjectRight{
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: flex-start;
        width: 25% ;

        .downloadbox{
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 20px;
            border: 1px solid var(--grey4);
            border-radius: 10px;
            cursor: pointer;

            .group{
                display: flex;
                flex-direction: column;
                gap: 5px;

                p{
                    font: 11px 'montRegular';
                    color: var(--black1);
                    line-height: 17px;
                }
                h4{
                    font: 18px 'montBold';
                    color: var(--blue);
                }
            }
        }

        .mapBox{
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
            padding: 20px;
            border: 1px solid var(--grey4);
            border-radius: 10px;

            &.buttonWrap{
                gap: 10px;
            }

            h2{
                font: 14px 'montBold';
                color: var(--black);
                line-height: 21px;
            }

            .amountBox{
                padding: 16px 10px;
                position: relative;
                background: transparent;
                text-align: center;
                width: 100%;
                border-radius: 10px;

                span{
                    color: var(--black);
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 14.4px;
                }
                
                p{
                    font-size: 18px;
                    font-family: var(--montBold);
                    font-weight: 800;
                    line-height: 21.6px;
                    margin-top: 5px;
                }

                &:first-child{
                    background: rgba(15, 169, 88, 0.1);
                    color: #0FA958;
                }

                &:nth-child(2){
                    background: rgba(115, 115, 115, 0.1);
                    color: #737373;
                }

                &:nth-child(3){
                    background: rgba(255, 0, 0, 0.1);
                    color: #FF0000;
                }
            }
        }
    }
}

//properties

.propertyTable{
    padding: 20px;
}

//booking
.bookingWrapper{
    padding: 20px;
}


table {
    border-collapse: collapse;
    width: 100%;
    position: relative;
}

thead {
    background-color: var(--black10);

    th {
        padding: 16px 20px;
        text-align: left;
        font: 14px var(--montBold);
        line-height: 17px;
        color: var(--black1);
        min-width: max-content;
    }
}

tbody {
    
    tr {
        border-bottom: 1px dashed var(--grey4);
    }

    td > div{
        // padding: 12px 20px;
        text-align: left;
        font: 13px var(--montRegular);
        line-height: 16px;
        color: var(--black);
        min-width: max-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;

        &.rating{
            color: var(--black1);
        }

        img{
            max-width: 32px;
            max-height: 32px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 4px  ;
        }
    }
}

//TimerLineWrapper

.timelineWrapper .time:last-child::before {
    height: 0;
}

.timelineWrapper .time::before{
    content: "";
    position: absolute;
    border-left: 1px dashed #D4D4D4;
    height: 151px;
    width: 1px;
    top: 19px;
    left: -15px;
    overflow: hidden;
}

.timelineWrapper .timeBox:last-child .time::before{
    border: none;
}

.timelineWrapper .time::after{
    position: absolute;
    content: '';
    top: 5px;
    left: -20px;
    width: 10px;
    height: 10px;
    background: #7757F4;
    border-radius: 50%;
}

.timelineWrapper{
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .timeBox{
        p{
            font: 13px var(--montRegular);
            line-height: 20.8px;
            color: var(--black5);
            margin-top: 10px;
        }

        .time{
            font: 12px var(--montSemiBold);
            line-height: 20px;
            color:var(--black1);
            font-weight: 600;
            position: relative;
            margin-top: 0;

            
        }

        .imgWrapper{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 0;

            img{
                max-width: 80px;
                border-radius: 10px;
                min-height: 80px;
                max-height: 80px;
            }
        }

        .namedocWrapper{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 0;

            .docCard{
                border: 1px dashed #E5E5E5;
                padding: 12px;
                display: flex;
                gap: 8px;
                align-items: center;
                width: fit-content;
                border-radius: 6px;
                cursor: pointer;
            }
        }
        .nameCardWrapper{
            padding: 10px 0;
            display: flex;
            gap: 10px;
            align-items: center;
            
            img{
                width: 20px;
            }
            p{
                margin-top: 0;
            }

            .namecard{
                display: flex;
                gap: 6px;
                align-items: center;
                
                .name{
                    font: 13px var(--montRegular);
                    color: #0E77D3;
                    line-height: 15.6px;
                    margin-top: 0;
                    cursor: pointer;
                }
            }
          
        }
    }
}

// prabh's code

.projectPrevWrap{
    position: relative;

    .projectFilter{
        margin-top: 20px;
        border-radius: 12px 12px 0 0;
    }
}

.propertyMaster{
    margin-top: 20px;
}

.propCardWrap{
    max-width: calc(25% - 15px);
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: var(--white);
    cursor: pointer;

    &.fullWidth{
       max-width: 100%;
       display: flex;
       align-items: flex-start;
       justify-content: flex-start;
        flex-wrap: nowrap;
       
       img{
            max-width: 20%;
       }

       .common{
            width: 100%;
       }

       .bottomSec{
        border-top: 0;
        padding-top: 0;
       }
    }

    img{
        width: 100%;
        height: auto;
        max-height: 190px;
        object-fit: cover;
        object-position: center;
    }

    .common{
        position: relative;
        width: 100%;
    }

    .innerCard{
        padding: 20px 20px;

        .upperSec{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .starRating{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;

                font-family: var(--montRegular);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: var(--black1);
            }
        }

        .midSec{
            position: relative;
            margin-top: 10px;

            h4{
                font-family: var(--springBold);
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;  
            }

            p{
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: var(--black1);
                margin-top: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
        }
    }

    .bottomSec{
        padding: 10px 20px;
        border-top: 2px solid var(--grey8);

        font-size: 11px;
        font-weight: 400;
        line-height: 17px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}
.greenText .tabData{
    color: rgba(15, 169, 88, 1);
}

.countBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
    position: relative;

    p{
        font-family: var(--montRegular);
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
        color: var(--black);

        b{
            font-weight: 600;
        }
    }

    .buttons{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}

.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--grey4);

    input{
        background: transparent;
        border: none;
        height: auto;
        outline: none;
    }
}

.projectTabs li.active::after{
    position: absolute;
    content: '';
    bottom: -84%;
    left: 0;
    border-bottom: 2px solid var(--blue);
    width: 100%;
}

.tablePagination{
    display: flex;
}

.tagsTags{
    margin-top: 30px;
}

.addagentButton{
    color: rgba(14, 119, 211, 1);
    border: 1px solid rgba(14, 119, 211, 1);
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 10px;
    height: unset;
    font: 14px 'montBold';
}