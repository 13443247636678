.formWrapper {

    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.04);

    h2 {
        font: 16px 'montHeavy';
        color: #171717;
        padding: 20px;
        border-bottom: 1px solid #F5F5F5;
    }


    .inputFormWrapper {
        padding: 20px 40px 20px 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;



        .inputFieldWrapper {
            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
    .ButtonWrapper{
        display: flex;
   
        justify-content: flex-end;
        
        .buttonRight{
            display: flex;
            gap: 5px;
        }
    }
}

.customUser{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    span{
        display: block;
        width: 100%;
    }

    div{
        max-width: 115px;
        width: 100%;
        height: 100px;
        overflow: hidden;
        cursor: pointer;
    }

    img{
        object-fit: cover;
    }

    input{
        display: none;
    }
}

.multiFormGroup{
    position: relative;

    .label{
        display: block;
        position: relative;
        font-size: 12px;
        line-height: 120%;
        color: var(--black);
        margin-bottom: 10px;
        font-weight: 400;
    }

    > div > div > div{
        background: var(--white);
        border-radius: 6px;
        width: 100%;
        height: 50px;
        padding: 10px 20px;
        border: 1px solid #EEEEEE;

        span{
            margin: 0;
            margin-right: 5px;
        }
    }
}

.formGroups {
    display: block;
    position: relative;
    width: 100%;
}


.formGroups label {
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 120%;
    color: var(--black);
    margin-bottom: 10px;
    font-weight: 400;
}

.impRed {
    color: var(--red-5);
}
.w-100{
    width: 100%;
}

.formGroups input,
.formGroups textarea {
    background: var(--white);
    height: 49px;
    border-radius: 6px;
    display: block;
    width: 100%;
    padding: 16px 20px;
    border: 1px solid #EEEEEE;
}

.formGroups textarea {
    height: auto;
    resize: none;
}

.formGroups input:focus {
    outline: none;
}

.FormError {
    font-size: 12px;
    color: var(--red-5);
}

.visible0 {
    visibility: hidden;
}

.formGroups input {
    color: var(--black1);
    font-weight: 400;
}

.projectWrapper {
    margin-top: 20px;
  
    .label {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
    }
  
    .projectItem {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
  
      .selectProject {
        margin-bottom: 10px;
  
        .react-select__control {
          border-color: #ccc;
          &:hover {
            border-color: #007bff;
          }
        }
  
        .react-select__menu {
          z-index: 100;
        }
      }
    }
  
    .removeProject {
      background-color: #ff4d4d;
      border: none;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer;
      align-self: flex-start;
      margin-top: 10px;
      &:hover {
        background-color: #e60000;
      }
    }
  
    .addProject {
      background-color: #007bff;
      border: none;
      padding: 10px 20px;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  .ButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  
    .buttonRight {
      display: flex;
      gap: 10px;
  
      .blueBtnLight,
      .greyButton {
        padding: 10px 20px;
      }
    }
  }

  .schemeWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }

  .newlabel{
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 120%;
    color: var(--black);
    margin-bottom: 10px;
    font-weight: 400;
  }
  .removeProjectnew{
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    width: 30%;
    margin-top: 30px;
  }

  .removeProjectred{
    background-color: #df1515db;
    border: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    width: 30%;
    margin-top: 30px;
  }