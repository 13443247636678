.loginWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 80px;
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
    background: url('../../../public/assets/img/loginBG.png') no-repeat;
    background-size: cover;

    @media (min-width: 1024px) and (max-width: 1540px) {
        padding: 30px 80px;
    }

    .loginBox {
        padding: 40px 30px;
        background: var(--white);
        border-radius: 20px;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 450px;
        gap: 30px;
        z-index: 99;

        .logoBox {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;

            img {
                position: relative;
                display: block;
            }

            .logoText {
                color: var(--black1);
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                width: 100%;
                text-align: center;
            }
        }

        .loginForm {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;

            &.signupForm {
                gap: 10px;
            }

            .forgetPass {
                color: var(--black1);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                display: block;
                position: relative;
                min-width: max-content;
            }

            .loginExtra {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }

        .buttonGroup {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 6px;
        }

        .signUp {
            color: var(--black1);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            span {
                color: var(--blue);
                font-weight: 600;
                cursor: pointer;
            }
        }

        .breakerBar {
            color: var(--black1);
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            position: relative;
            width: 100%;

            &::after,
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 10px;
                width: calc(50% - 20px);
                height: 1px;
                background: var(--grey6);
            }

            &::before {
                left: unset;
                right: 0;
            }
        }

    }
}

.forgetWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;

    .forgetBox {
        padding: 40px;
        background: var(--white);
        border-radius: 20px;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 450px;
        gap: 30px;
        z-index: 99;

        .forgetHead{
            position: relative;
            width: 100%;
            text-align: center;

            h4{
                color: var(--black);
                font-family: var(--springMedium);
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 10px;
            }

            p{
                color: var(--black1);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }
        .goBack{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;

            color: var(--blue);
            font-size: 12px;
            font-weight: 400;
            line-height: 100%;
        }
    }


    .blank {
        display: block;
        width: 100%;
    }
}

.thnkusubText{
    font-size: 14px !important;
    margin-bottom: 30px;
}

.thnkuheadText{
    margin-top: 40px;
}