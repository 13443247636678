.numberWrapper {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 12px 20px;
    border-radius: 12px;
    margin-top: 20px;

    h3 {
        font: 12px var(--montRegular);
        color: var(--black2);
        font-size: 11px;
        font-weight: 400;
        line-height: 16.5px;

        b {
            font-family: var(--montSemiBold);
            color: var(--black);
        }
    }

    ul {
        display: flex;
        gap: 10px;
        list-style-type: none;

        li {
            font: 11px var(--montSemiBold);
        }
    }

    .pagination{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        li{
            font-size: 11px;
            font-weight: 400;
            line-height: 13.2px;
            color: var(--black2);
            cursor: pointer;

            display: flex;
            align-items: center;
            transition: 0.4s ease-in;
            
            &.active,
            &:hover{
                transition: 0.4s ease-in;
                color: var(--blue);
            }
        }
    }
}
