.documentWrap {
    position: relative;

    .wrapFilter {
        position: relative;
        margin-top: 20px;
    }


    .propertyTable {
        padding: 20px;
        background: var(--white);
    }

    //booking
    .bookingWrapper {
        padding: 20px;
    }


    table {
        border-collapse: collapse;
        width: 100%;
        position: relative;
    }

    thead {
        background-color: var(--black10);

        th {
            padding: 16px 20px;
            text-align: left;
            font: 14px var(--montBold);
            line-height: 17px;
            color: var(--black1);
            min-width: max-content;
        }
    }

    tbody {

        tr {
            border-bottom: 1px dashed var(--grey4);
        }

        td>div {
            // padding: 12px 20px;
            text-align: left;
            font: 13px var(--montRegular);
            line-height: 16px;
            color: var(--black);
            min-width: max-content;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }
    }
}