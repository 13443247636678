.appWrapper{
  position: relative;
  display: block;
  max-height: 100vh;
  max-width: 100vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding-left: 0;
  transition: 0.4s ease-in;
  
  &.afterLogin{
    padding-left: 65px;

    .workingArea{
      max-height: calc(100vh - 62px);
    }

  }
  
  &.smallMenu{
    padding-left: 245px;
    transition: 0.4s ease-in
  }

  .workingArea{
    max-height: 100vh;
    height: 100vh;
    overflow: auto;
    background: var(--grey8);
    padding: 0 0;
    transition: 0.4s ease-in;
    // background: url('../public/assets/img/forgotBg.svg');

    &.afterLogin{
      padding: 30px 20px;
      transition: 0.4s ease-in;
    }
  }
}

.hide{
  display: none;
}
