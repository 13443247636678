.formWrapper {

    background: white;
    border-radius: 10px;
    margin-top: 20px;

    h2 {
        font: 16px 'montHeavy';
        color: #171717;
        padding: 20px;
        border-bottom: 1px solid #F5F5F5;
    }


    .inputFormWrapper {
        padding: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;



        .inputFieldWrapper {
            display: flex;
            gap: 20px;
            align-items: center;
        }
    }

    .formGroups {
        display: block;
        position: relative;
        width: 100%;
    }
    
    
    .formGroups label {
        display: block;
        position: relative;
        font-size: 12px;
        line-height: 120%;
        color: var(--black);
        margin-bottom: 10px;
        font-weight: 400;
    }
    
    .impRed {
        color: var(--red-5);
    }
    
    .formGroups input,
    .formGroups textarea,
    .formGroups select {
        background: var(--white);
        height: 49px;
        border-radius: 6px;
        display: block;
        width: 100%;
        padding: 16px 20px;
        border: 1px solid #EEEEEE;
    }
    
    .formGroups textarea {
        height: auto;
        resize: none;
    }
    
    .formGroups input:focus {
        outline: none;
    }
    
    .FormError {
        font-size: 12px;
        color: var(--red-5);
    }
    
    .visible0 {
        visibility: hidden;
    }
    
    .formGroups input {
        color: var(--black1);
        font-weight: 400;
    }
    
}

.ButtonWrapper {
    display: flex;
    justify-content: flex-end;
  
    .buttonRight{
        display: flex;
        width: 20%;
        gap: 6px;
    }
}

.features{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.additionalWrap{
    flex-wrap: wrap;
    
    .additionalFeatures{
        display: block;
        position: relative;
        width: 100%;
    }
}

.labelz{
    display: block;
    position: relative;
    font-size: 12px;
    line-height: 120%;
    color: var(--black);
    margin-bottom: 10px;
    width: 100%;
    font-weight: 400;
}

.uploadBox{
    flex-wrap: wrap;
    gap: 0 !important;

    label:nth-child(2){
        width: 100%;
        min-height: 200px;
        max-height: 200px;
        padding: 16px 20px;
        border: 1px solid #EEEEEE;
        max-width: 100%;
        width: 100%;

        background: url('../../../../public/assets/svg/uploadCloud.svg') no-repeat;
        background-position: 50% 50%;
        
        svg{
            display: none;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            margin-top: 50px;
        }

    }
}