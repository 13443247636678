ul{
    list-style-type: none;
}

.receiptWrapper {
    padding: 20px 40px 40px;
    background: var(--white);
    border-radius: 10px;

    p {
        font: 13px 'montRegular';
        color: rgba(115, 115, 115, 1);
    }

    h2 {
        font: 16px 'montHeavy';
        color: rgba(115, 115, 115, 1);
    }

    .receipt1Head {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        align-items: center;
    }

    .receipt1Body {

        .topBody {
            padding: 40px 0 30px;
            display: flex;
            justify-content: space-between;
            gap: 60px;
            align-items: flex-start;

            h2 {
                margin-bottom: 8px;
            }

            .topBodyLeft {
                display: flex;
                flex-direction: column;
                gap: 40px;
                justify-content: space-between;
            }

            .contentWrapRight {
                text-align: right;
            }

        }

        .MidBody {
            padding: 20px;
            border: 1px solid rgba(245, 245, 245, 1);
            border-radius: 6px;

            .borderLight {
                border-top: 1px solid rgba(0, 0, 0, 0.06);
                width: 100%;
                margin-top: 20px;
                padding-top: 20px;

            }

            h2 {
                margin-bottom: 15px;
            }

            
        }

.bottomBody{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    svg
    {
        width: 89px;
    }

    .content{
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 10px;align-items: flex-start;
    }
    .contentSign{
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 10px;align-items: center;
    }
}
    }
}

.outerDivWrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;


    .contentWrap {
        display: flex;
        gap: 10px;
        width: 40%;

        p {
            min-width: 150px;
        }
    }
}

p span {
    font: 13px 'montSemiBold';
}

.fontblack {
    color: rgba(0, 0, 0, 1) !important;
}


.cards {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 30px;

    .greenCard {
        background: rgba(15, 169, 88, 0.1);
        padding: 16px;
        max-width: 150px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(15, 169, 88, 1);
        }
    }

    .greyCard {
        background: rgba(115, 115, 115, 0.1);
        padding: 16px;
        max-width: 150px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(115, 115, 115, 1);
        }
    }

    .redCard {
        margin-left: auto;
        background: rgba(255, 0, 0, 0.1);
        padding: 16px;
        max-width: 150px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(255, 0, 0, 1);
        }
    }

}
.mt40{
    margin-top: 40px;
}
.bookon span{
    color: black;
}

.profilepicWrap{

    img{
        max-width: 100px;
        border-radius: 10px;
    }

    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 22px;

    p{
        font: 11px 'montRegular';
        color: #737373;
    }

    .tag{
        padding: 3px 6px;
        font: 14px 'montRegular';
        border-radius: 4px;
        background: #F0F0F0;
        width: fit-content;
        color: black;
    }
    .name{
        font: 18px "montBold";
        color: black;
        margin-top: 6px;
    }
}


.outerDivWrap2{

    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .contentWrap{
        display: flex;
        gap: 6px;
        align-items: center;
        width: 48%;
    }
}

.outerDivWrap3{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .contentWrap{
        display: flex;
        gap: 6px;
    }
}


.letterHead{
    padding: 40px 0;

    h6{
        font: 16px 'montBold';
        color: #737373;
    }
    h5{
        font: 13px 'montSemiBold';
        color: black;
        margin-top: 8px;
    }
    p{
        font: 13px 'montRegular';
        color: #737373;
        line-height: 15.6px;
    }

    .mt4{
        margin-top: 4px;
    }
}

.letterbody{
    padding: 40px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.outerDivWrapOuter{
    margin-top: 15px;

    .outerDivWrap{
        flex-wrap: unset;
        flex-direction: column;

        p:nth-child(1){
            min-width: 40%;
        }
        p{
            min-width: max-content;
        }
    }
}

.termandConditions{
    padding: 40px 0;

    ul{
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 15px;

        li{
            display: flex;
            gap: 10px;
            align-items: flex-start;
        }
    }
}

.receiptFooter.signature{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
}
.receiptFooter.rightSide{
    width: 100%;
    text-align: right;
}


.subHeadingReceipt {
    margin-top: 40px;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: black !important;

}
.subHeadingReceipt span{
    font: 16px 'montBold';
    color: rgba(115, 115, 115, 1) !important;

}

.spacebetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

/* src/Receipt.module.scss */
.receiptWrapper {
    /* existing styles */
}

.acceptance {
    margin-top: 20px;
    p {
        margin-bottom: 10px;
    }
}

.signature {
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;
}

.authorizedSign {
    font-style: italic;
}

.mt4 {
    margin-top: 20px;
}
