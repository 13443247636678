.bookingWrap{
    position: relative;

    .bookingFilter{
        margin-top: 20px;
    }

}

.tableWrap{
    margin-top: 20px;
    position: relative;
    max-height: calc(100vh - 265px);
    height: 100vh;
    overflow: auto;
    padding: 20px;
    padding-top: 0;
    border-top: 20px solid var(--white);
    background: var(--white);

    thead{
        position: sticky;
        top: 0;
        z-index: 99;
    }
}