.timelineWrap{
    position: relative;
    margin-top: 0;
    max-width: 100%;
    width: 100%;

    .timeline{
        border-left: 4px solid var(--pink);
        padding-left: 12px;

        h5{
            font-family: var(--montBold);
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
            color: var(--black5);
        }
        
        small{
            font-size: 12px;
            color: var(--grey12);
        }
        
        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: var(--grey5);
        }
        
        span{
            font-size: 13px;
            font-weight: 400;
            color: var(--grey5);
            line-height: 19px;

            b{
                color: var(--blue);
            }
        }
    }
}