/* src/Popup.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.popup.show {
    display: flex;
}

.popupinner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 80%;
    height: 96vh;
    overflow: scroll;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.popupHeader{
    border-bottom: 1px solid var(--grey4);
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    
    h3{
        font: 24px 'montBold';
    }

    svg{
        cursor: pointer;
    }
}