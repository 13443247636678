.projectOverview {
    padding: 0 0;
    border-radius: 10px;
}

.cards {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    .greenCard {
        background: rgba(15, 169, 88, 0.1);
        padding: 16px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(15, 169, 88, 1);
        }
    }

    .greyCard {
        background: rgba(115, 115, 115, 0.1);
        padding: 16px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(115, 115, 115, 1);
        }
    }

    .redCard {
        margin-left: auto;
        background: rgba(255, 0, 0, 0.1);
        padding: 16px;
        
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4 {
            font: 12px 'montThin';
        }

        p {
            font: 18px 'montBold';
            color: rgba(255, 0, 0, 1);
        }
    }

}


.projectTabs {
    list-style-type: none;
    display: flex;
    gap: 40px;
    padding: 20px;
    background: var(--white);
    margin-top: 20px;

    li {
        font: 16px var(--springBold);
        color: var(--black1);
        cursor: pointer;
        transition: 0.4s ease-in-out;

        &:hover {
            color: var(--blue);
        }

        &.active {
            color: var(--blue);
        }
    }
}

.projectPages {
    background: var(--white);
}



//about

.aboutProjectWrapper {
    padding: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-start;

    h3 {
        font: 22px var(--montBold);
        color: var(--black);
    }

    .contentText {
        font: 12px montRegular;
        color: var(--black1);
        margin-top: 15px;
        line-height: 18px;
    }

    h5 {
        margin-top: 35px;
        font: 14px montBold;
        line-height: 21px;
    }

    .aboutProjectLeft {
        width: 75%;


        .headRating {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .tag {
                padding: 4px 10px;
                background-color: #FFA8001A;
                border-radius: 4px;
                gap: 4px;
                display: flex;
                align-items: center;
                font: 14px var(--montSemibold);

            }
        }

        .locationDetails {
            display: flex;
            gap: 15px;
            margin-top: 5px;

            .box {
                font: 11px var(--montRegular);
                color: var(--black1);
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .projectDetailWrap {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-top: 10px;

            .projectTag {
                border: 1px dashed var(--grey7);
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 5px;
                color: var(--black1);
                font: 13px var(--montRegular);
            }
        }

        .featuresWrap {
            display: flex;
            gap: 12px 35px;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;

            .contentText {
                margin-top: 0;
            }
        }
    }

    .aboutProjectRight {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: flex-start;
        width: 25%;

        .downloadbox {
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 20px;
            border: 1px solid var(--grey4);
            border-radius: 10px;
            cursor: pointer;

            .group {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;
                text-align: center;

                p {
                    font: 11px 'montRegular';
                    color: var(--black1);
                    line-height: 17px;
                }

                h4 {
                    font: 18px montBold;
                    color: var(--blue);
                }
            }
        }

        .mapBox {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
            padding: 20px;
            border: 1px solid var(--grey4);
            border-radius: 10px;

            h2 {
                font: 14px montBold;
                color: var(--black);
                line-height: 21px;
            }
        }
    }
}

//properties

.propertyTable {
    padding: 20px;
}

//booking
.bookingWrapper {
    padding: 20px;
}


table {
    border-collapse: collapse;
    width: 100%;
    position: relative;
}

thead {
    background-color: var(--black10);

    th {
        padding: 16px 20px;
        text-align: left;
        font: 14px var(--montBold);
        line-height: 17px;
        color: var(--black1);
        min-width: max-content;
    }
}

tbody {

    tr {
        border-bottom: 1px dashed var(--grey4);
    }

    td>div {
        // padding: 12px 20px;
        text-align: left;
        font: 13px var(--montRegular);
        line-height: 16px;
        color: var(--black);
        min-width: max-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}

//TimerLineWrapper

.timelineWrapper .time:last-child::before {
    height: 0;
}

.timelineWrapper .time::before {
    content: "";
    position: absolute;
    border-left: 1px dashed #D4D4D4;
    height: 151px;
    width: 1px;
    top: 19px;
    left: -15px;
    overflow: hidden;
}

.timelineWrapper .timeBox:last-child .time::before {
    border: none;
}

.timelineWrapper .time::after {
    position: absolute;
    content: '';
    top: 5px;
    left: -20px;
    width: 10px;
    height: 10px;
    background: #7757F4;
    border-radius: 50%;
}

.timelineWrapper {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .timeBox {
        p {
            font: 13px var(--montRegular);
            line-height: 20.8px;
            color: var(--black5);
            margin-top: 10px;
        }

        .time {
            font: 12px var(--montSemiBold);
            line-height: 20px;
            color: var(--black1);
            font-weight: 600;
            position: relative;
            margin-top: 0;


        }

        .imgWrapper {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 0;
        }

        .namedocWrapper {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 0;

            .docCard {
                border: 1px dashed #E5E5E5;
                padding: 12px;
                display: flex;
                gap: 8px;
                align-items: center;
                width: fit-content;
                border-radius: 6px;
            }
        }

        .nameCardWrapper {
            padding: 10px 0;
            display: flex;
            gap: 10px;
            align-items: center;

            p {
                margin-top: 0;
            }

            .namecard {
                display: flex;
                gap: 6px;
                align-items: center;

                .name {
                    font: 13px var(--montRegular);
                    color: #0E77D3;
                    line-height: 15.6px;
                    margin-top: 0;
                }
            }

        }
    }
}

// prabh's code

.projectPrevWrap {
    position: relative;

    .projectFilter {
        margin-top: 20px;
    }
}

.propertyMaster {
    margin-top: 20px;
}

.propCardWrap {
    max-width: calc(25% - 15px);
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: var(--white);
    cursor: pointer;

    img {
        width: 100%;
    }

    .innerCard {
        padding: 20px 20px;

        .upperSec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .starRating {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;

                font-family: var(--montRegular);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: var(--black1);
            }
        }

        .midSec {
            position: relative;
            margin-top: 10px;

            h4 {
                font-family: var(--springBold);
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: var(--black1);
                margin-top: 10px;
            }
        }
    }

    .bottomSec {
        padding: 10px 20px;
        border-top: 2px solid var(--grey8);

        font-size: 11px;
        font-weight: 400;
        line-height: 17px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}

.OwnerShipWrap {

    display: flex;
    padding: 20px;
    gap: 20px;
    background: rgba(224, 239, 253, 0.4);

    .ownerLeft {
        width: 30%;
        background: var(--white);

        p {
            padding: 15px 20px;
            font: 14px 'montBold';
            color: rgba(170, 170, 170, 1);
            cursor: pointer;
            transition: 0.4s ease-in-out;
            border: 1px solid rgba(245, 245, 245, 1);

            &:hover {
                font: 14px 'montSemibold';
                color: rgba(14, 119, 211, 1);
            }

            &.active {
                font: 14px 'montSemibold';
                color: rgba(14, 119, 211, 1);
                background: rgba(224, 239, 253, 0.4);
            }
        }
    }

    .ownerRight {
        width: 70%;
    }



}

.redCard {
    margin-left: auto;
    background: rgba(255, 0, 0, 0.1);
    padding: 16px;
    
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    h4 {
        font: 12px 'montThin';
    }

    p {
        font: 18px 'montBold';
        color: rgba(255, 0, 0, 1);
    }
}

.purpuleTag {
    background: rgba(90, 26, 183, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    width: fit-content;
    font: 11px 'montSemibold';
    color: rgba(90, 26, 183, 1);
}


.bookingHistoryWrap {
    padding: 20px;
    background: var(--white);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-start;

    .bookingHistoryBox {
        display: flex;
        flex-direction: column;
        gap: 10px;

    .flexbox{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .bookingHistoryContentBox{
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2{
            font: 18px 'montbold';

            span{
                font: 16px 'montThin';
            }
        }

        h5{
            font: 12px 'montRegular';
            color: rgba(115, 115, 115, 1);
        }
    }

       

        .contentBoxHistory {
            display: flex;
            gap: 10px;

            p {
                font: 13px 'montRegular';
                color: rgba(0, 0, 0, 1);
            }

            .pLeft {
                color: rgba(115, 115, 115, 1);
                min-width: 150px;
            }
        }
    }
}

.amountBox{
    padding: 16px 10px;
    position: relative;
    background: transparent;
    text-align: center;
    width: 100%;
    border-radius: 10px;

    span{
        color: var(--black);
        font-size: 12px;
        font-weight: 300;
        line-height: 14.4px;
    }
    
    p{
        font-size: 18px;
        font-family: var(--montBold);
        font-weight: 800;
        line-height: 21.6px;
        margin-top: 5px;
    }

    &:first-child{
        background: rgba(15, 169, 88, 0.1);
        color: #0FA958;
    }

    &:nth-child(2){
        background: rgba(115, 115, 115, 0.1);
        color: #737373;
    }

    &:nth-child(3){
        background: rgba(255, 0, 0, 0.1);
        color: #FF0000;
    }
}

.flexBOxWrap{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
