/* src/Popup.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.popup.show {
    display: flex;
}

.popupbody{
    padding-top: 30px;
}

.popupinner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 100%;
    max-width: 700px;
    height: 70vh;
    overflow: scroll;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.popupHeader{
    border-bottom: 1px solid var(--grey4);
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    
    h3{
        font: 24px 'montBold';
    }

    svg{
        cursor: pointer;
    }
}

.uploadBox{
    flex-wrap: wrap;
    gap: 0 !important;

    label:nth-child(2){
        width: 100%;
        min-height: 200px;
        max-height: 200px;
        padding: 16px 20px;
        border: 1px solid #EEEEEE;
        max-width: 100%;
        width: 100%;

        background: url('../../../../public/assets/svg/uploadCloud.svg') no-repeat;
        background-position: 50% 50%;
        
        svg{
            display: none;
        }

        div{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            margin-top: 50px;
        }

    }
}

.uploadViewBox{
    padding: 20px;
    background: #FAFAFA;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    &:first-child{
        margin-top: 20px;
    }
}

.viewDetail{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img{
        cursor: pointer;
    }

    p{
        font: 14px 'montRegular';
        color: black;
        cursor: pointer;

    }
}

.buttonWrapper{
    margin-top: 40px;
}

.fileUploaderBox{
    position: relative;
    width: 100%;

    label{
        min-height: 320px;
        max-width: 100%;
        border: 1px dashed var(--blue);
        width: 100%;

        background: url('./uploadCont.svg') no-repeat;
        background-position: center;

        display: flex;
        align-items: center;
        justify-content: center;

        > svg{
            display: none;
        }

        > svg + div{
            justify-content: center;
            margin-top: 120px;
        }
    }

}

.uploadWrapper{
    max-height: 420px;
    overflow: auto;
}