.agentWrap{
    position: relative;
}

.agentFilter{
    margin-top: 20px;
    cursor: pointer;
}

.cardArrangement{
    position: relative;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    .myAgent{
        max-width: calc(20% - 16px);
    }
}

