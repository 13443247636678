.ToastWrap{
    position: fixed;
    bottom: 30px;
    right: 30px;

    z-index: 999999;

    font-size: 16px;
    line-height: 120%;
    color: #404040;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;


    padding: 14px 32px;


    border-radius: 10px;
    max-width: max-content;
    width: 100%;
}

.successToast{
    border: 2px solid #D7EFDF;
    background: #EBF7EF;
    display: none;
}
.successToast.active {
    display: flex;
}

.errorToast{
    border: 2px solid #FACBCB;
    background: #FDE5E5;
    visibility: hidden;
}

.errorToast.active {
    visibility: visible;
}

/* New Toast */

.ToastWrapper{
    border-radius: 6px;
background: var(--white);
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26);
width: 100%;
max-width: max-content;
min-height: 53px;
display: flex;
justify-content: space-between;
align-items: center;
gap: 10px;
padding: 16px;
position: absolute;
bottom: 20px;
right: 30px;
z-index: 999;
}

.ToastTextmain{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 1px solid #E5E7EB;
    padding-left: 10px;
    gap: 30px;
}

.ToastTextmain .text14{
    color: #6B7280;
    line-height: 150%; 
}

.ToastTextmain span{
    cursor: pointer;
}
/*End New Toast */

@media (max-width: 767px) {
    .ToastWrap{
        font-size: 14px;
        padding: 15px 15px;
        max-width: calc(100% - 40px);
        width: 100%;
        right: 20px;
    }
}