.timeLineWrapper {
    display: block;

    .dM{
        padding: 0;
    }

    .Kq{
        width: auto;
    }

    ._3n{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        gap: 2px;
        padding: 12px 15px;
        width: 45px;
        height: 65px;
        padding: 12px, 14px;
        border-radius: 650px;    
    }

    .FC{
        background: var(--pink);
        color: var(--white);
    }
    
    .FC .Lx{
        color: var(--white);
    }
    
    .FC ._1g{
        background: var(--pink);
    }

    .Lx{
        font-family: var(--montRegular);
        font-size: 12px;
        font-weight: 600;
        line-height: 18.52px;
        color: var(--black1);
        margin-bottom: 0;
    }

    ._1g{
        font-size: 14px;
        font-weight: 600;
        line-height: 20.96px;
    }

}