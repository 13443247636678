header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 40px;
    width: 100%;
    border-bottom: 1px solid var(--black6);
    background: var(--white);

    .rightWrap{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        .search{
            padding-right: 15px;
            border-right: 1px solid var(--black6);
            cursor: pointer;
        }

        .notificationWrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
        }
    }
}