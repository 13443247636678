.newWrapper {
    position: relative;

    .newHeading {
        font-family: var(--springSemiBold);
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0px;
        color: var(--black);
    }

    .newsMajorWrap {
        margin-top: 20px;
        padding: 20px 20px;
        border-radius: 10px;
        background: var(--white);

        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 40px;

        .newsMajorLeft {
            max-width: calc(45% - 20px);
            width: 100%;
        }

        .newsMajorRight {
            max-width: calc(55% - 20px);
            width: 100%;
        }

        .ytBox {
            width: 100%;
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        .newBox {
            position: relative;
            padding: 20px 0;

            h4 {
                font-family: var(--springSemiBold);
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: var(--black);
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                margin-top: 15px;
                color: var(--black1);
            }

            .newsUserWrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin-top: 15px;
                position: relative;

                .floatingTag {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                span {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 17px;
                    color: var(--black1);
                }

                p {
                    color: var(--black);
                    font-family: var(--montRegular);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    margin-top: 0;
                }
            }
        }
    }

    .upcomingEvent {
        position: relative;
        margin-top: 20px;
        border-radius: 10px;
        background: var(--white);

        h4 {
            font-family: var(--montBold);
            font-size: 16px;
            font-weight: 800;
            line-height: 19px;
            padding: 20px 20px;
            border-bottom: 1px solid var(--grey2);
        }

        .cardWrap {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 20px;
            padding: 20px 20px;
        }

        .mainCard {
            max-width: calc(33% - 9px);
        }
    }

    .instaPost {
        position: relative;
        margin-top: 20px;
        border-radius: 10px;
        background: var(--white);

        .headFlex {
            padding: 20px 20px;
            border-bottom: 1px solid var(--grey2);

            h4 {
                font-family: var(--montBold);
                font-size: 16px;
                font-weight: 800;
                line-height: 19px;
            }
        }

        .cardWrap{
            padding: 20px 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            img{
                width: 100%;
                max-width: calc(20% - 10px);
            }
        }
    }
}

.breaker {
    display: block;
    position: relative;
    width: 100%;
    border-top: 1px solid var(--grey4);
    margin: 20px 0;
}